/* eslint-disable no-console */
(function() {
    'use strict';

    angular.module('vobeApp').controller('PropertyVouchersController', PropertyVouchersController);

    function PropertyVouchersController(
        $scope,
        $rootScope,
        $state,
        previousState,
        $location,
        currentLanguage,
        currentProperty,
        currentPropertyGroup,
        propertyVouchers,
        propertyCashVouchers,
        propertyGiftCards,
        Property,
        PropertyVouchers,
        VouchersProcessService,
        allVouchers,
        User,
        $animate,
        $timeout,
        $document,
        $window,
        VouchersViewSettings,
        shoppingCart,
        shoppingCartConfigs,
        propertyCategories,
        groupCategories,
        PromotionCodeService,
        $sce,
        $log,
        $q,
        GroupVouchers,
        CurrencyExchange,
        $localStorage,
        $uiRouterGlobals,
        $filter,
        translationHandler,
        $cookies
    ) {
        'ngInject';
        var vm = this;

        //for the moment $log prints in production profile, so overwriting with console
        $log = console;
        console.log('property vouchers controller');

        vm.filter = $filter;//important
        var categoryParameterName = 'cat';
        var minPriceParameterName = 'minPrice';
        var maxPriceParameterName = 'maxPrice';
        var pageParameterName = 'page';
        var viewSettings = {
            viewLayoutTileActive: 'three',
            voucherPoolOrderBy: {
                name: 'sequence',
                reverse: false,
            },
        };

        var cashVouchersPresent = false;
        var propertyConfig = {};

        vm.$onInit = function () {
            vm.websiteType = (typeof currentPropertyGroup !== 'undefined') ? 'group' : 'property';
            vm.voucherClassCounter = [];//only one reference to array
            vm.channel = $uiRouterGlobals.params.channel;
            vm.currentProperty = currentProperty;
            vm.currentPropertyGroup = currentPropertyGroup;
            var isGroup = (typeof vm.currentPropertyGroup !== 'undefined');
            vm.isGroup = isGroup;
            vm.shoppingCartConfigs = shoppingCartConfigs;
            vm.promotionCode = {
                code: $uiRouterGlobals.params.promotionCode
            };

            vm.applyPromotionCode = applyPromotionCode;
            vm.removePromotionCode = removePromotionCode;
            vm.processingPromotionCode = false;

            vm.viewClass = 'property-detail';
            vm.openCashModal = openCashModal;
            vm.openPackageModal = openPackageModal;

            vm.viewLayoutTileDesign = 'default';
            vm.descriptionLines = $uiRouterGlobals.params.descriptionLines || 1;

            propertyConfig = shoppingCartConfigs['' + currentProperty.hotelId];
            if (
                propertyConfig &&
                propertyConfig.configMap &&
                propertyConfig.configMap.defaultItemsPerRow &&
                propertyConfig.configMap.defaultItemsPerRow == '3'
            ) {
                viewSettings.viewLayoutTileActive = 'three';
            }
            if (
                propertyConfig &&
                propertyConfig.configMap &&
                propertyConfig.configMap.defaultItemsPerRow &&
                propertyConfig.configMap.defaultItemsPerRow == '4'
            ) {
                viewSettings.viewLayoutTileActive = 'four';
            }
            if (
                propertyConfig &&
                propertyConfig.configMap &&
                propertyConfig.configMap.defaultItemsPerRow &&
                propertyConfig.configMap.defaultItemsPerRow == '6'
            ) {
                viewSettings.viewLayoutTileActive = 'six';
            }
            if (
                propertyConfig &&
                propertyConfig.configMap &&
                propertyConfig.configMap.defaultSortOption &&
                propertyConfig.configMap.defaultSortOption == '1'
            ) {
                viewSettings.voucherPoolOrderBy.name = 'minValueAfterDiscount';
            }
            if (
                propertyConfig &&
                propertyConfig.configMap &&
                propertyConfig.configMap.viewLayoutTileDesign &&
                ['text', 'default'].includes(propertyConfig.configMap.viewLayoutTileDesign)
            ) {
                vm.viewLayoutTileDesign = propertyConfig.configMap.viewLayoutTileDesign;
            }
            if (propertyConfig && propertyConfig.configMap && propertyConfig.configMap.descriptionLines) {
                vm.descriptionLines = propertyConfig.configMap.descriptionLines;
            }

            vm.defaultShowCategoriesBoxTreshold = 2;//two or more
            vm.showCategoriesBoxTreshold = vm.defaultShowCategoriesBoxTreshold;
            if (
                propertyConfig &&
                propertyConfig.configMap &&
                propertyConfig.configMap.showCategoriesBoxTreshold &&
                propertyConfig.configMap.showCategoriesBoxTreshold != ''
            ) {
                try {
                    var parsedShowCategoriesBoxTreshold = parseInt(propertyConfig.configMap.showCategoriesBoxTreshold);
                    if(parsedShowCategoriesBoxTreshold>0){
                        vm.showCategoriesBoxTreshold = parsedShowCategoriesBoxTreshold;
                    }
                } catch (e) {}
            }
            vm.showCategoriesBoxType = 1;
            if (
                propertyConfig &&
                propertyConfig.configMap &&
                propertyConfig.configMap.showCategoriesBoxType &&
                propertyConfig.configMap.showCategoriesBoxType != 1
            ) {
                try {
                    vm.showCategoriesBoxType = parseInt(propertyConfig.configMap.showCategoriesBoxType);
                } catch (e) {}
            }
            vm.showSortControls = true;
            if (
                propertyConfig &&
                propertyConfig.configMap &&
                propertyConfig.configMap.hideSortOption &&
                propertyConfig.configMap.hideSortOption == 'true'
            ) {
                try {
                    vm.showSortControls = false;
                } catch (e) {}
            }
            vm.showColumnControls = true;
            if (
                propertyConfig &&
                propertyConfig.configMap &&
                propertyConfig.configMap.hideItemsPerRow &&
                propertyConfig.configMap.hideItemsPerRow == 'true'
            ) {
                try {
                    vm.showColumnControls = false;
                } catch (e) {}
            }
            vm.showPromoControls = true;
            if (
                propertyConfig &&
                propertyConfig.configMap &&
                propertyConfig.configMap.hasActivePromotionCodes &&
                propertyConfig.configMap.hasActivePromotionCodes == 'false'
            ) {
                try {
                    vm.showPromoControls = false;
                } catch (e) {}
            }
            vm.mobileExpandedCategories = false;
            if (
                propertyConfig &&
                propertyConfig.configMap &&
                propertyConfig.configMap.mobileExpandedCategories &&
                propertyConfig.configMap.mobileExpandedCategories == 'true'
            ) {
                try {
                    vm.mobileExpandedCategories = true;
                } catch (e) {}
            }

            /*promo disabled for group*/
            if(isGroup){
                //vm.showPromoControls = false;
            }

            var savedSettings = VouchersViewSettings.getSettings();
            vm.settings = angular.extend(viewSettings, savedSettings);

            vm.voucherPoolOrderBy = vm.settings.voucherPoolOrderBy;

            vm.viewLayoutTiles = {
                three: {
                    breakpoint: {
                        xs: { cards: 1 },
                        sm: { cards: 1 },
                        md: { cards: 2 },
                        lg: { cards: 3 },
                    },
                    activeBreakpoint: {},
                },
                four: {
                    breakpoint: {
                        xs: { cards: 1 },
                        sm: { cards: 2 },
                        md: { cards: 3 },
                        lg: { cards: 4 },
                    },
                    activeBreakpoint: {},
                },
                six: {
                    breakpoint: {
                        xs: { cards: 1 },
                        sm: { cards: 3 },
                        md: { cards: 4 },
                        lg: { cards: 6 },
                    },
                    activeBreakpoint: {},
                },
            };


            setActiveBreakpoints(getViewSize());

            //reference shortcuts
            vm.viewLayoutTileActive = vm.settings.viewLayoutTileActive;
            //reference to active layout
            vm.viewLayout = vm.viewLayoutTiles[vm.viewLayoutTileActive];

            vm.property = currentProperty;
            vm.previousState = previousState.name;

            vm.activateSlick = activateSlick;
            vm.activateSlickFeatured = activateSlickFeatured;

            /**
             * CATEGORIES
             */

            vm.propertyCategories = propertyCategories;
            vm.groupCategories = groupCategories;
            vm.voucherClassActive = $uiRouterGlobals.params.cat || ($uiRouterGlobals.current.data ? $uiRouterGlobals.current.data.voucherClassActive : 'all');
            vm.multipleCategoryFilter = [];
            vm.voucherFirstCategoryActive = {classId: 'all'};
            vm.activateVoucherClass = activateVoucherClass;
            vm.sortVoucherClass = sortVoucherClass;
            vm.filterByVoucherClass = filterByVoucherClass;
            vm.filterGiftCardByVoucherClass = filterGiftCardByVoucherClass;
            vm.showCategoriesBox = true;//always true

            /**
             * PRICING
             */
            var minPriceParsed = 0;
            try {
                minPriceParsed = parseInt($uiRouterGlobals.params[minPriceParameterName] ? $uiRouterGlobals.params[minPriceParameterName] : minPriceParsed);
            } catch (e) {
                $log.error("Error parsing min price");
            }
            var maxPriceParsed = 0;
            try {
                maxPriceParsed = parseInt($uiRouterGlobals.params[maxPriceParameterName] ? $uiRouterGlobals.params[maxPriceParameterName] : maxPriceParsed);
            } catch (e) {
                $log.error("Error parsing min price");
            }
            vm.priceFilter = {
                minPrice: minPriceParsed,
                maxPrice: maxPriceParsed
            };
            activatePriceRange(minPriceParsed,maxPriceParsed);

            /**
             * FEATURED VOUCHERS
             */

            vm.featuredVouchers = []; //these are amounts
            if (propertyConfig && propertyConfig.configMap && propertyConfig.configMap.featuredCashVouchers) {
                var value = propertyConfig.configMap.featuredCashVouchers.replace(/ /g, '');
                vm.featuredVouchers = value.split(',');
            }


            //vm.allVouchers = allVouchers;
            //vm.propertyVouchers = propertyVouchers;
            //vm.propertyCashVouchers = propertyCashVouchers;

            vm.propertyGiftCards = propertyGiftCards;

            vm.displayCashVoucher = false;
            vm.disableVoucherClass = true; //Always true since Nov 2017. Reason: always disable voucher class
            vm.shoppingCart = shoppingCart;

            /**
             * VARIABLES THAT WILL CHANGE ON VOUCHERS RELOAD
             */
            vm.initialVoucherClassCounter = [];
            vm.showCategoriesBoxUseful = true;
            vm.priceIntervalsCounter = {};

            //multiple category filter values ... initally empty.. which means all.
            vm.multipleCategoryFilter = vm.multipleCategoryFilter || [];
            vm.cardsCounter = 0;

            processVouchers(allVouchers);
            activateVoucherClassAsync();
            activatePriceRange(vm.priceFilter.minPrice,vm.priceFilter.maxPrice);

            /**
             * SLICK CAROUSEL CONFIG - FOR CATEGORIES
             */

            if(vm.allVouchers.wrapper) {
                angular.forEach(vm.allVouchers.wrapper.voucherPools, function (pool, pkey) {
                    if (pool.type === 'cash' || pool.type === 'cash-wildcard') {
                        cashVouchersPresent = true;
                    }
                });
            }
            if(!cashVouchersPresent && isGroup && vm.allVouchers.eligibleVouchers){
                angular.forEach(vm.allVouchers.eligibleVouchers, function(poolIdsArray, hotelId){
                    angular.forEach(poolIdsArray, function(voucherIdsArray, poolId){
                        if(poolId<0){
                            cashVouchersPresent = true;
                        }
                    });
                });
            }
            var sliderArows = true; //false;
            var slidesPerRow = 3;
            var slideCategories = vm.voucherClassCounter.length + 1 /*all*/ + (cashVouchersPresent ? 0 : 1);
            var slickRows = 1; //parseInt(slideCategories / slidesPerRow);
            if ($(window).width() < 568) {
                slickRows = 1;
                slidesPerRow = 1;
            } else if ($(window).width() < 768) {
                slickRows = 2;
                slidesPerRow = 2;
            } else if ($(window).width() < 1025) {
                slickRows = 3;
                slidesPerRow = 3;
            }
            if (slidesPerRow * slickRows < slideCategories) {
                sliderArows = true;
            }

            vm.slickConfig = {
                enabled: false,
                autoplay: false,
                draggable: true,
                centerMode: false,
                dots: false,
                arrows: sliderArows,
                swipe: true,
                //slidesToShow: slidesPerRow,
                //slidesPerRow: slidesPerRow,
                rows: slickRows,
                infinite: false,
                method: {},
                prevArrow: '<span class="glyphicon glyphicon-chevron-left slick-prev" aria-hidden="true"></span>',
                nextArrow: '<span class="glyphicon glyphicon-chevron-right slick-next" aria-hidden="true"></span>',
                event: {
                    beforeChange: function(event, slick, currentSlide, nextSlide) {},
                    afterChange: function(event, slick, currentSlide, nextSlide) {},
                },
            };

            if (slickRows === 1) {
                vm.slickConfig.slidesToShow = slidesPerRow;
            } else {
                vm.slickConfig.slidesPerRow = slidesPerRow;
            }
            /** END SLICK CAROUSEL CONFIG FOR CATEGORIES */

            /**
             * SLICK CAROUSEL CONFIG - FOR FEATURED VOUCHERS
             */
            var isRTL = translationHandler.isRTL((currentLanguage || 'en' ).split('_')[0].split('-')[0]);
            var feat_v_slidesToShow = 1;
            var feat_v_slidesPerRow = 1;
            var feat_v_slickRows = 1; //parseInt(slideCategories / slidesPerRow);
            vm.feat_v_slickConfig = {
                rtl: isRTL,
                enabled: false,
                autoplay: false,
                draggable: true,
                centerMode: false,
                dots: true,
                arrows: true,
                swipe: true,
                slidesToShow: feat_v_slidesToShow,
                slidesPerRow: feat_v_slidesPerRow,
                rows: feat_v_slickRows,
                infinite: false,
                method: {},
                prevArrow: '<div class="slick-prev"><button class="w3-button w3-text-grey w3-card-2 w3-circle w3-padding" style="line-height: 2;" role="button">\n' +
                '<span class="glyphicon glyphicon-chevron-'+(isRTL ? 'right' : 'left')+'" aria-hidden="true"></span>\n' +
                '</button></div>',
                nextArrow: '<div class="slick-next"><button class="w3-button w3-text-grey w3-card-2 w3-circle w3-padding" style="line-height: 2;" role="button">\n' +
                '<span class="glyphicon glyphicon-chevron-'+(isRTL ? 'left' : 'right')+'" aria-hidden="true"></span>\n' +
                '</button></div>',
                event: {
                    beforeChange: function(event, slick, currentSlide, nextSlide) {},
                    afterChange: function(event, slick, currentSlide, nextSlide) {},
                },
            };
            /** END SLICK CAROUSEL CONFIG FOR FEATURED VOUCHERS */

            vm.filterCashVouchersByFeatured = filterCashVouchersByFeatured;
            vm.handlePageChange = handlePageChange;
            vm.getImageFileName = getImageFileName;

            vm.appliedPromotionCode;
            if (
                shoppingCart &&
                shoppingCart.shoppingCartDiscounts &&
                shoppingCart.shoppingCartDiscounts.promotionCodeIds &&
                shoppingCart.shoppingCartDiscounts.promotionCodeIds.length > 0
            ) {

                angular.forEach(shoppingCart.shoppingCartDiscounts.discounts, function(d, i) {
                    console.log('checking dicount...', d)
                    //if is already set, skip looping
                    if(vm.appliedPromotionCode){
                        vm.promotionCode.code = vm.appliedPromotionCode.code;
                        return;
                    }
                    if (!isGroup && !d.automatic && shoppingCart.shoppingCartDiscounts.promotionCodeIds.includes(d.id) && d.propertyId == currentProperty.hotelId) {
                        vm.appliedPromotionCode = d;
                        vm.promotionCode.code = d.code;
                    }

                    if (isGroup && !d.automatic && shoppingCart.shoppingCartDiscounts.promotionCodeIds.includes(d.id)) {
                        angular.forEach(currentPropertyGroup.hotels,function(h,hIndex){
                            if( d.propertyId == h.hotelId){
                                vm.appliedPromotionCode = d;
                                vm.promotionCode.code = d.code;
                            }
                        });
                    }
                    //TODO: please test
                    else if (!d.automatic && shoppingCart.shoppingCartDiscounts.promotionCodeIds.includes(d.id)  && d.propertyId == null && isGroup){
                        //for a group only 'masteradmin' level promo can be applied to pricing
                        //therefore only under 'd.propertyId == null' condition it's allowed to be displayed as applied
                        vm.appliedPromotionCode = d;
                        vm.promotionCode.code = d.code;
                    }
                });
            }

            //set paymentRefToken cookie if present in the stateparams
            if ($uiRouterGlobals.params.pRef) {
                const expiryDate = new Date(Date.now() + 1 * (60 * 60 * 1000) );
                $cookies.put('paymentRefToken', $uiRouterGlobals.params.pRef, { expires: expiryDate });``
            }

            //allows for category sequences
            vm.useCategorySequence = false;
            var propertyId = vm.currentProperty.hotelId;
            if (vm.shoppingCartConfigs[propertyId] && vm.shoppingCartConfigs[propertyId].configMap && vm.shoppingCartConfigs[propertyId].configMap.useCategorySequence && vm.shoppingCartConfigs[propertyId].configMap.useCategorySequence == 'true') {
                vm.useCategorySequence = true;
            }

            vm.onCurrencyUpdated = onCurrencyUpdated;
            vm.min = Math.min;
        }

        var itemRemovedFromCartEvent = $rootScope.$on('itemRemovedFromCart', function(event, args) {
            console.log('property-vouchers controller itemRemoveFromCart', args.shoppingCart);
            vm.shoppingCart = args.shoppingCart;
        });

        $scope.$watch('vm.settings.viewLayoutTileActive', function() {
            console.log('watch vm.settings.viewLayoutTileActive');
            angular.forEach(vm.viewLayoutTiles, function(value, key) {
                if (key === vm.settings.viewLayoutTileActive) {
                    vm.viewLayout = value;
                }
            });
            VouchersViewSettings.setSettings(vm.settings);
        });

        $scope.$watch('vm.settings.voucherPoolOrderBy', function() {
            console.log('watch vm.settings.voucherPoolOrderBy');
            VouchersViewSettings.setSettings(vm.settings);
        });

        $window.addEventListener(
            'orientationchange',
            function() {
                console.log('event listender, orientationchange');
                var newViewSize = getViewSize();
                //console.log("new view size: "+newViewSize);
                setActiveBreakpoints(newViewSize);
                $scope.$apply();
            },
            false
        );

        /**
         * LISTENERS
         */

        $scope.$on('vouchersFinishedRendering', function(vouchersFinishedRenderingEvent) {
            console.log('finished rendering');
            //var vouchersRow = angular.element(document.getElementById('vouchers-row'));
            //$document.scrollToElementAnimated(vouchersRow);
        });

        var setActiveBreakpoints = function(viewSize) {
            vm.viewLayoutTiles.three.activeBreakpoint = vm.viewLayoutTiles.three.breakpoint[viewSize];
            vm.viewLayoutTiles.four.activeBreakpoint = vm.viewLayoutTiles.four.breakpoint[viewSize];
            vm.viewLayoutTiles.six.activeBreakpoint = vm.viewLayoutTiles.six.breakpoint[viewSize];
        };

        function filterCashVouchersByFeatured(value, index, array) {
            return ~vm.featuredVouchers.indexOf('' + value.totalRate.priceMin);
        }

        /**
         * PRICE FILTER FUNCTIONS
         */
        vm.handlePriceFilter = function(event) {
            $log.info('handlePriceFilter', event.minPrice, event.maxPrice);

            var promise = $q.defer();

            if(typeof vm.currentPropertyGroup !== 'undefined'){
                GroupVouchers.get(
                    {
                        id: vm.currentPropertyGroup.id,
                        code : vm.currentPropertyGroup.code,
                        channel: $uiRouterGlobals.params.channel,
                        passkey: $uiRouterGlobals.params.passkey,
                        languageKey: (currentLanguage || 'en' ).split('_')[0].split('-')[0],
                        promoCode: undefined ,
                        selectedCategoryCodes:vm.multipleCategoryFilter,
                        minPrice: event.minPrice,
                        maxPrice: event.maxPrice,
                        intervalBars: 50,
                        sort: "price",
                        sortOrder: "asc",
                        page: 1,//vm.currentPage+1,//always reset page
                        pageSize: 24
                    },
                    function(groupVouchers) {
                        VouchersProcessService.processGroupVouchers(groupVouchers,vm.currentProperty,vm.shoppingCart,vm.shoppingCartConfigs,vm.propertyGiftCards);
                        processVouchers(groupVouchers);
                        activateVoucherClassAsync();
                        activatePriceRange(event.minPrice,event.maxPrice);
                        promise.resolve();
                    }
                );
            }
            else {
                activateVoucherClassAsync();
                activatePriceRange(event.minPrice,event.maxPrice);
                promise.resolve();
            }
            return promise;

        };

        vm.filterCashOnly = function(voucherPool, index, array) {

            return (voucherPool.type === 'cash')
        };

        vm.filterPrice = function(value, index, array) {
            //$log.info('filterPrice', value);
            if (vm.priceFilter.minPrice === 0 && vm.priceFilter.maxPrice === 0) {
                return true;
            }

            var priceMatched = false;

            angular.forEach(value.vouchers, function(v, key) {
                if (
                    vm.priceFilter.minPrice <= v.totalRate.priceMin &&
                    vm.priceFilter.maxPrice >= v.totalRate.priceMin
                ) {
                    priceMatched = true;
                }
            });
            return priceMatched;
        };

        function activatePriceRange(minPrice,maxPrice) {

            if(!vm.showCategoriesBox || vm.showCategoriesBoxType != 2){
                return;
            }

            vm.priceFilter.minPrice = minPrice;
            vm.priceFilter.maxPrice = maxPrice;

            if ($stateParams[minPriceParameterName] !== minPrice || $stateParams[maxPriceParameterName] !== maxPrice) {
                //$stateParams[categoryParameterName] = voucherClass;
                //$state.params[categoryParameterName] = voucherClass;
                //$state.transitionTo($state.current.name,$stateParams,{location: false,reload:false,inherit:false,notify:false});
                //$state.go($state.current.name,$stateParams,{notify:false,reload:false});//this will not change url param
                //PLEASE DON'T USE ANY ABOVE AS THIS WILL ALWAYS RELOAD PAGE
                //THERE IS reloadOnSearch: false ON THIS VIEW SO THE BELOW WILL NOT TRIGGER RELOAD
                var searchObject = {};
                angular.copy($location.search(),searchObject);
                searchObject[minPriceParameterName] = minPrice;
                searchObject[maxPriceParameterName] = maxPrice;
                $location.search(searchObject); //this will reload whole page
            }

            console.info('pricingRangeActive',  { minPrice: minPrice, maxPrice: maxPrice });
            $scope.$broadcast('pricingRangeActive',  { minPrice: minPrice, maxPrice: maxPrice });

            return vm.priceFilter;

        }


        /**
         * CATEGORY FILTER FUNCTIONS
         */

        //filter based on `showCategoriesBoxType` value
        vm.filterCategory = function(value, index, array) {
            return vm.filterByMultipleCategories(value, index, array);
        };


        //multiple category filter onUpdate handler ...
        vm.handleCategoriesFilter = function(event) {
            var promise = $q.defer();
            vm.voucherClassActive = event.selectedCategories.join(',');//will be used in processVouchers();
            //if this is a group we reload vouchers
            //do this if page is more than 1
            if(typeof vm.currentPropertyGroup !== 'undefined'){
                GroupVouchers.get(
                    {
                        id: vm.currentPropertyGroup.id,
                        code : vm.currentPropertyGroup.code,
                        channel: $uiRouterGlobals.params.channel,
                        passkey: $uiRouterGlobals.params.passkey,
                        languageKey: (currentLanguage || 'en' ).split('_')[0].split('-')[0],
                        promoCode: undefined ,
                        selectedCategoryCodes:event.selectedCategories,
                        minPrice: vm.priceFilter.minPrice,
                        maxPrice: vm.priceFilter.maxPrice,
                        intervalBars: 50,
                        sort: "price",
                        sortOrder: "asc",
                        page: 1,//vm.currentPage+1,//always reset page
                        pageSize: 24
                    },
                    function(groupVouchers) {
                        VouchersProcessService.processGroupVouchers(groupVouchers,vm.currentProperty,vm.shoppingCart,vm.shoppingCartConfigs,vm.propertyGiftCards);
                        processVouchers(groupVouchers);
                        vm.multipleCategoryFilter = event.selectedCategories;
                        activateVoucherClassAsync();
                        activatePriceRange(vm.priceFilter.minPrice,vm.priceFilter.maxPrice);
                        promise.resolve();
                    }
                );
            } else {
                vm.multipleCategoryFilter = event.selectedCategories;
                activateVoucherClassAsync();
                activatePriceRange(vm.priceFilter.minPrice,vm.priceFilter.maxPrice);
                promise.resolve();
            }
            return promise;
        };

        function activateVoucherClassAsync(){
            console.log('activateVoucherClassAsync');
            determineVoucherPoolOrderBy();
            $timeout(function() {
                if (!vm.voucherClassActive) {
                    vm.activateVoucherClass('all');
                } else {
                    vm.activateVoucherClass(vm.voucherClassActive);
                }
                vm.activateSlick();
            }, 250);
        }

        function determineVoucherPoolOrderBy() {
            console.log('determineVoucherPoolOrderBy')
            var mainOrderBy = 'sequence';
            if (vm.settings && vm.settings.voucherPoolOrderBy && vm.settings.voucherPoolOrderBy.name) {
                var mainOrderBy = vm.settings.voucherPoolOrderBy.name.split("_")[0];
            }
            var voucherCategoryShortCodes = vm.voucherClassActive ? vm.voucherClassActive.split(",") : [];
            if (mainOrderBy == 'sequence' && vm.useCategorySequence && voucherCategoryShortCodes.length == 1 && voucherCategoryShortCodes[0] != 'all') {
                vm.voucherPoolOrderBy = 'sequence_' + voucherCategoryShortCodes[0];
                console.log('vm.voucherPoolOrderBy: ', vm.voucherPoolOrderBy);
            } else {
                vm.voucherPoolOrderBy = mainOrderBy;
            }

            vm.settings.voucherPoolOrderBy = vm.settings.voucherPoolOrderBy != vm.voucherPoolOrderBy ? vm.voucherPoolOrderBy : vm.settings.voucherPoolOrderBy;
            console.log('vm.voucherPoolOrderBy: ', vm.settings);
        }
        function activateVoucherClass(requiredVoucherClass) {

            var isGroup = (typeof vm.currentPropertyGroup !== 'undefined');
            var voucherCategoryShortCodes = vm.voucherClassActive ? vm.voucherClassActive.split(",") : [];

            //1. fallback check
            var count = 0;
            angular.forEach(voucherCategoryShortCodes,function(code,ci){
                var vCounterObj = getVoucherClassCounterByName(code);
                if (vCounterObj && vCounterObj.counter && vCounterObj.counter > 0) {
                    count += vCounterObj.counter;
                }
                if(ci==0){
                    vm.voucherFirstCategoryActive = vCounterObj;
                }
            });

            //fallback: check if we have vouchers for this category
            if(count==0){
                voucherCategoryShortCodes = ['all'];
            }

            //2. set values again after fallback check
            var voucherClass = voucherCategoryShortCodes.join(',');
            vm.voucherClassActive = voucherClass;
            vm.multipleCategoryFilter = voucherCategoryShortCodes;

            if ($uiRouterGlobals.params.cat !== voucherClass) {
                var searchObject = {};
                angular.copy($location.search(),searchObject);
                searchObject[categoryParameterName] = voucherClass;
                $location.search(searchObject); //this will reload whole page
            }

            $scope.$broadcast('voucherClassActive', { voucherClassActive: vm.voucherClassActive });

            //todo: is this still required?
            var cashVouchersPresent = false;
            if(vm.allVouchers.wrapper) {
                angular.forEach(vm.allVouchers.wrapper.voucherPools, function (pool, pkey) {
                    if (pool.type === 'cash' || pool.type === 'cash-wildcard') {
                        cashVouchersPresent = true;
                    }
                });
            }
            if(!cashVouchersPresent && isGroup && vm.allVouchers.eligibleVouchers){
                angular.forEach(vm.allVouchers.eligibleVouchers, function(poolIdsArray, hotelId){
                    angular.forEach(poolIdsArray, function(voucherIdsArray, poolId){
                        if(poolId<0){
                            cashVouchersPresent = true;
                        }
                    });
                });
            }
            if ((voucherClass === 'all' || voucherClass === 'cash') && (cashVouchersPresent)) {
                vm.displayCashVoucher = true;
            } else {
                vm.displayCashVoucher = false;
            }

            //send dataLayer impressions for view_item_list
            sendDataLayerImpressions(vm.voucherClassActive);
        }

        function handlePageChange(){
            var promise = $q.defer();
            vm.paginationLoading = true;
            if(typeof vm.currentPropertyGroup !== 'undefined'){
                GroupVouchers.get(
                    {
                        id: vm.currentPropertyGroup.id,
                        code : vm.currentPropertyGroup.code,
                        channel: $uiRouterGlobals.params.channel,
                        passkey: $uiRouterGlobals.params.passkey,
                        languageKey: (currentLanguage || 'en' ).split('_')[0].split('-')[0],
                        promoCode: undefined ,
                        selectedCategoryCodes:vm.multipleCategoryFilter,
                        minPrice: vm.priceFilter.minPrice,
                        maxPrice: vm.priceFilter.maxPrice,
                        intervalBars: 50,
                        sort: "price",
                        sortOrder: "asc",
                        page: vm.currentPage,
                        pageSize: 24
                    },
                    function(groupVouchers) {
                        VouchersProcessService.processGroupVouchers(groupVouchers,vm.currentProperty,vm.shoppingCart,vm.shoppingCartConfigs,vm.propertyGiftCards);
                        processVouchers(groupVouchers);
                        activateVoucherClassAsync();
                        activatePriceRange(vm.priceFilter.minPrice,vm.priceFilter.maxPrice);
                        promise.resolve();
                    }
                );
            }
            var timeoutPromise = $timeout(1000*10);//10 seconds timeout
            timeoutPromise.then(function(){
                //try to resolve the promise after 10sec just in case
                promise.resolve();
            });
            promise.promise.then(function(){
                vm.paginationLoading = false;//stop overlay
            });
        }

        function sortVoucherClass(voucherClass) {
            switch (voucherClass.classId) {
                case 'all':
                    return -9999;
                case 'cash':
                    return -9998;
                default:
                    return voucherClass.sequence; //-1*voucherClass.counter;
            }
        }

        /**
         *
         * @param value this is a pool
         * @param index
         * @param array
         * @returns {boolean}
         */
        function filterByVoucherClass(value, index, array) {
            //$log.info('filterByVoucherClass', value, index, array);
            //value in here is a pool
            if (!vm.voucherClassActive || vm.voucherClassActive === 'all') {
                return true;
            }
            var voucherClassMatched = false;
            //first look at the voucher class
            if (vm.disableVoucherClass) {
                $log.info('filterByVoucherClass', vm.voucherClassActive, value.category);
                angular.forEach(value.vouchers, function(v, key) {
                    if (v.categories && v.categories[vm.voucherClassActive]) {
                        voucherClassMatched = true;
                    }
                });
                //Todo: what os this?
                if (value.category && value.category.category.voucherClassOverride === vm.voucherClassActive) {
                    voucherClassMatched = true;
                }
            } else {
                angular.forEach(value.vouchers, function(v, key) {
                    if (v.voucherClass === vm.voucherClassActive) {
                        voucherClassMatched = true;
                    }
                });
            }
            //check if voucher is assigned to this category

            return voucherClassMatched;
        }
        //actual multiple category filter.
        vm.filterByMultipleCategories = function(value, index, array) {
            if (vm.multipleCategoryFilter.length === 0) {
                //$log.info('filterByMultipleCategories NOTHING');
                return true;
            }
            if (vm.multipleCategoryFilter.includes("all")) {
                //$log.info('filterByMultipleCategories all');
                return true;
            }

            var voucherCategoryMatched = false;

            if (vm.disableVoucherClass) {
                //package vouchers only
                angular.forEach(value.vouchers, function(v, key) {
                    //$log.info(v);
                    angular.forEach(v.categories, function(c, ci) {
                        if (vm.multipleCategoryFilter.includes(c.classId)) {
                            voucherCategoryMatched = true;
                        }
                    });
                });
            } else {
                angular.forEach(value.vouchers, function(v, key) {
                    if (v.voucherClass === vm.voucherClassActive) {
                        voucherCategoryMatched = true;
                    }
                });
            }

            return voucherCategoryMatched;
        };

        function filterGiftCardByVoucherClass(value, index, array) {
            if (!vm.voucherClassActive || vm.voucherClassActive === 'all' || vm.voucherClassActive === 'cash') {
                return true;
            }
            var voucherClassMatched = false;
            return voucherClassMatched;
        }


        vm.filterVouchersByFeatured = function(value, index, array) {
            var voucherCategoryMatched = false;

            //package vouchers only
            angular.forEach(value.vouchers, function(v, key) {
                //$log.info(v);
                angular.forEach(v.categories, function(c, ci) {
                    if (c.classId=='featured') {
                        voucherCategoryMatched = true;
                    }
                });
            });

            return voucherCategoryMatched;
        };

        vm.filterNoOp = filterNoOp;
        function filterNoOp(value, index, array) {
            return true;
        }

        function getEligibleVouchers(cValue) {
            var vouchers = [];
            angular.forEach(vm.allVouchers.wrapper.voucherPools, function(pool, poolkey) {
                angular.forEach(pool.vouchers, function(v, vkey) {
                    if (cValue.voucherIds) {
                        angular.forEach(cValue.voucherIds, function(vId, vIdKey) {
                            if (v.id == vId) {
                                vouchers.push(v);
                            }
                        });
                    }
                });
            });
            return vouchers;
        }

        function getEligibleVouchersByClassName(name) {
            var vouchers = [];
            angular.forEach(vm.allVouchers.wrapper.voucherPools, function(pool, poolkey) {
                angular.forEach(pool.vouchers, function(v, vkey) {
                    if ('' + v.voucherClass == '' + name) {
                        vouchers.push(v);
                    }
                });
            });
            return vouchers;
        }

        function getCategoryByFriendlyName(name) {
            var category;
            angular.forEach(vm.propertyCategories, function(cValue, cKey) {
                if (cValue.friendlyName == name) {
                    category = cValue;
                }
            });
            return category;
        }

        function getCategoryByVoucherClassName(name) {
            var category;
            angular.forEach(vm.propertyCategories, function(cValue, cKey) {
                if (cValue.voucherClassOverride == name) {
                    category = cValue;
                }
            });
            return category;
        }

        function getPreparedCategoryByVoucherClassName(name) {
            var category;
            angular.forEach(vm.propertyCategories, function(cValue, cKey) {
                if (cValue.voucherClassOverride == name) {
                    category = prepareCategoryAsObject(cValue, name);
                }
            });
            return category;
        }

        function prepareCategoryAsObject(cValue, classId) {
            return {
                classId: classId,
                counter: cValue.voucherIds ? cValue.voucherIds.length : 0,
                isCategory: true,
                category: cValue,
                sequence: cValue.sequence,
            };
        }

        function getVoucherClassCounterByName(name) {
            var voucherClassCounter;
            angular.forEach(vm.voucherClassCounter, function(vClass, key) {
                if (name == vClass.classId) {
                    voucherClassCounter = vClass;
                }
            });
            return voucherClassCounter;
        }
        vm.getVoucherClassCounterByName = getVoucherClassCounterByName;

        function getByCashCategoryVoucherIds(vouchersResponse){
            var voucherIds = [];
            //if this is special 'cash' category, firstly lets add all VISIBLE voucher IDs to this category:
            if (vouchersResponse && vouchersResponse.wrapper && vouchersResponse.wrapper.voucherPools){
                angular.forEach(allVouchers.wrapper.voucherPools,function(pool,poolId){
                    if(pool.type=='cash' || pool.type=='cash-wildcard'){
                        angular.forEach(pool.vouchers, function(voucher, vindex){
                            if(!voucherIds.includes(voucher.id)){
                                voucherIds.push(voucher.id);
                            }

                        });

                    }
                });
            }

            //secondly add all cash vouchers to this category from other pages (this is for group)
            if(vouchersResponse.eligibleVouchers){
                angular.forEach(vouchersResponse.eligibleVouchers, function(poolIdsArray, hotelId){
                    angular.forEach(poolIdsArray, function(voucherIdsArray, poolId){
                        if(poolId<0){
                            angular.forEach(voucherIdsArray, function(vid, vindex){
                                if(!voucherIds.includes(vid)){
                                    voucherIds.push(vid);
                                }
                            });
                        }
                    });
                });
            }
            return voucherIds;
        }

        function getByCategoryVoucherIds(vouchersResponse, categoryWrapper, isGroup){
            var voucherIds = [];

            var isCashCategory = categoryWrapper.classId == 'cash';
            if(isCashCategory) {
                //init vouchers with cash vouchers first
                voucherIds = getByCashCategoryVoucherIds(vouchersResponse);
            }


            if(!isGroup){
                if(categoryWrapper.category) {
                    //this checks voucherIds[] in category object and fetches in full vouchers from the feed
                    var eligibleVouchers = getEligibleVouchers(categoryWrapper.category);
                    if(eligibleVouchers){
                        angular.forEach(eligibleVouchers,function (v,i) {
                            voucherIds.push(v.id);
                        });
                    }
                }
            }
            else if (isGroup) {
                if(allVouchers && allVouchers.eligibleVouchers && allVouchers.eligibleVouchers!=null){
                    angular.forEach(allVouchers.eligibleVouchers,function(eligibleVoucherByHotelIdMap,hotelId){
                        angular.forEach(eligibleVoucherByHotelIdMap,function(eligibleVoucherByHotelIdAndPoolIdList,poolId){
                            if(categoryWrapper.category && categoryWrapper.category.voucherIds){
                                angular.forEach(categoryWrapper.category.voucherIds,function(vid,i){
                                    angular.forEach(eligibleVoucherByHotelIdAndPoolIdList,function(evid,vindex){
                                        if(vid==evid){
                                            voucherIds.push(evid);
                                        }
                                    });
                                });
                            }
                        });
                    });
                }
            }
            return voucherIds;
        }

        function calculateCategoryCounter(categoryWrapper,allVouchers,isGroup){
            var voucherIds = getByCategoryVoucherIds(allVouchers,categoryWrapper,isGroup);
            return voucherIds ? voucherIds.length : 0;
        }


        function updateCategorySequenceOnPools() {
            angular.forEach(vm.allVouchers.wrapper.voucherPools, function(pool, poolkey) {
                angular.forEach(pool.vouchers, function(v, vkey) {
                    angular.forEach(v, function(vPropertyValue, vPropertyKey) {
                        if(vPropertyKey.indexOf("sequence_")===0){
                            //check if pool has this sequence
                            var poolExistingCategorySequenceValue = pool[vPropertyKey];
                            if(!poolExistingCategorySequenceValue || poolExistingCategorySequenceValue>vPropertyValue){
                                pool[vPropertyKey] = vPropertyValue;
                            }
                        }
                    });
                });
            });
        }


        /**
         * OTHER FUNCTIONS
         */

        function activateSlick() {
            if (vm.slickConfig.rows == 1) {
                $('slick').removeClass('voucher-class-slick-double');
                $('slick').addClass('voucher-class-slick');
            }
            vm.slickConfig.enabled = true;
        }

        function activateSlickFeatured() {
            $('slick').removeClass('voucher-class-slick-double');
            $('slick').addClass('voucher-class-slick');
            vm.feat_v_slickConfig.enabled = true;
        }

        function getViewSize() {
            var viewportWidth = $(window).width();
            if (viewportWidth < 767) {
                return 'sm';
            }
            if (viewportWidth < 992) {
                return 'sm';
            } else if (viewportWidth < 1199) {
                return 'md';
            }
            return 'lg';
        }

        /**
         * IMAGES
         */
        function getImageFileName(imageUrl) {
            if (!imageUrl) {
                return '';
            }

            var parts = imageUrl.split('/');
            return parts[parts.length - 1];
        }

        //location Boolean or "replace" (default true), If true will update the url in the location bar, if false will not. If string "replace", will update url and also replace last history record.
        //inherit Boolean (default true), If true will inherit url parameters from current url.
        //relative stateObject (default $state.$current), When transitioning with relative path (e.g '^'), defines which state to be relative from.
        //notify Boolean (default true), If true will broadcast $stateChangeStart and $stateChangeSuccess events.
        //reload v0.2.5 Boolean (default false), If true will force transition even if the state or params have not changed, aka a reload of the same state. It differs from reloadOnSearch because you'd use this when you want to force a reload when everything is the same, including search params.

        function openCashModal(params) {
            var stateName = 'cash-detail';
            //var p = angular.extend(params || {}, $stateParams);
            var options = {
                location: true,
                inherit: true,
                relative : $state.$current,
                reload: false
            };
            sendDataLayerProductClick(params.voucherPool);
            console.log('opening cash modal: ', params)
            $state.go(stateName, params, options);
        }

        function openPackageModal(params) {
            console.log('=> openPackageModal', params)
            var stateName = 'package-detail';
            var options = {
                location: true,
                inherit: true,
                relative : $state.$current,
                reload: false
            };
            //sendDataLayerProductClick(params.voucherPool, params.voucherId);
            console.log('opening state: ', params.voucherId)
            $state.go(stateName, params, options);
        }


        function reloadView(promotionCode) {
            $state.transitionTo($state.current.name, {promotionCode: promotionCode}, { reload: true, inherit: true });
        }

        function applyPromotionCode(event) {
            vm.processingPromotionCode = true;

            if (event) {
                event.preventDefault();
            }
            //we need to fetch all voucher endpoints again with promo price
            //so just refresh the whole page
            reloadView(vm.promotionCode.code);
        }

        function removePromotionCode(event) {
            vm.processingPromotionCode = true;

            if (event) {
                event.preventDefault();
            }

            var callback = function(httpResponse, shoppingCart, ngModel) {
                vm.processingPromotionCode = false;
                reloadView('');
            };

            //first remove the promo code from the shopping cart in session
            //then we need to fetch all voucher endpoints again without promo price
            //so just refresh the whole page in callback
            PromotionCodeService.removePromocode(
                shoppingCart,
                vm.promotionCode.code,
                vm.appliedPromotionCode.id,
                callback,
                callback
            );
        }

        //clean up events...
        $scope.$on('$destroy', function() {
            itemRemovedFromCartEvent();
        });

        function processVouchers(vouchersResponse){

            vm.allVouchers = vouchersResponse;
            vm.currentPage = vouchersResponse.page+1 || $uiRouterGlobals.params.pageParameterName || 1;

            //RESET
            //vm.voucherClassCounter.length = 0;//clear entries but don't reference new array
            vm.voucherClassCounter = [];
            vm.uncategorizedVouchers = 0;
            vm.priceIntervalsCounter = {};
            vm.cardsCounter = 0;

            /**
             * CATEGORIES PART
             * 1. populate vouchers with categories and regenerate voucherClassCounter
             */

            //if we have vouchers
            if (vouchersResponse) {

                var isGroup = (typeof currentPropertyGroup !== 'undefined');

                var cashVouchersPresent = false;
                if (vouchersResponse && vouchersResponse.wrapper && vouchersResponse.wrapper.voucherPools) {
                    angular.forEach(vouchersResponse.wrapper.voucherPools, function (pool, pkey) {
                        if (pool.type == 'cash' || pool.type == 'cash-wildcard') {
                            cashVouchersPresent = true;
                        }
                    });
                }
                if(!cashVouchersPresent && isGroup && vouchersResponse.eligibleVouchers){
                    angular.forEach(vouchersResponse.eligibleVouchers, function(poolIdsArray, hotelId){
                        angular.forEach(poolIdsArray, function(voucherIdsArray, poolId){
                            if(poolId<0){
                                cashVouchersPresent = true;
                            }
                        });
                    });
                }


                //get config values for category hide "all"
                var hideCategoryAll = false;
                if (propertyConfig && propertyConfig.configMap && propertyConfig.configMap.hideCategoryAll) {
                    hideCategoryAll = (propertyConfig.configMap.hideCategoryAll === 'true');
                }
                var hideCategoryFeatured = true;

                //1. create voucher categories
                var categoryAll = getPreparedCategoryByVoucherClassName('all');
                if (categoryAll && !hideCategoryAll) {
                    vm.voucherClassCounter.push(categoryAll);
                } else if (!hideCategoryAll) {
                    var totalVouchers = vouchersResponse.nrOfElements ? vouchersResponse.nrOfElements : vouchersResponse.voucherPoolsCount;
                    vm.voucherClassCounter.push({classId: 'all', counter: totalVouchers});
                }

                var categoryCash;
                //2. populate 'voucherClassCounter' from legacy voucher class or new categories
                //Removed in Nov 2017. Reason: always disable voucher class
                //if(propertyConfig && propertyConfig.configMap && propertyConfig.configMap.disableVoucherClass && propertyConfig.configMap.disableVoucherClass=='true' ){
                //	vm.disableVoucherClass = true;
                //}
                if (!vm.disableVoucherClass) {
                    //LEGACY: removed
                }

                //prepare category wrapper for property categories
                angular.forEach(vm.propertyCategories, function (cValue, cKey) {

                    var isCashCategory = cValue.friendlyName == 'cash';
                    cValue.voucherIds = cValue.voucherIds || [];

                    var categoryWrapper = prepareCategoryAsObject(cValue, cValue.friendlyName);

                    //if this is special 'cash' category, firstly lets add all cash-wildcard vouchers IDs to this category
                    //IMPORTANT: for group it will also add cash-wildcard vouchers from other pages
                    if (isCashCategory && vouchersResponse) {
                        var voucherIds = getByCashCategoryVoucherIds(vouchersResponse);
                        if (voucherIds) {
                            angular.forEach(voucherIds, function (vid, i) {
                                if (!cValue.voucherIds.includes(vid)) {
                                    cValue.voucherIds.push(vid);
                                }
                            });
                        }
                    }

                    //get default category if present
                    var defaultCategoryId = 0;
                    if (propertyConfig && propertyConfig.configMap && propertyConfig.configMap.defaultVoucherCategoryId) {
                        defaultCategoryId = parseInt(propertyConfig.configMap.defaultVoucherCategoryId);
                    }

                    //other categories will have vouchers populated
                    if (cValue.voucherIds && cValue.voucherIds.length > 0) {

                        if (cValue.friendlyName == 'cash') {
                            categoryCash = categoryWrapper;
                        }
                        //double check the category counter (from actual returned vouchers by the endpoint)
                        //IMPORTANT: for group it will also count in vouchers from other pages
                        //NOTE: it will include cash-wildcard & package  if it's 'cash' category
                        var counter = calculateCategoryCounter(categoryWrapper, vouchersResponse, isGroup);
                        categoryWrapper.counter = counter;
                        if (counter > 0) {

                            //make the category active if set in config
                            if (defaultCategoryId > 0 && categoryWrapper.category.id === defaultCategoryId) {
                                vm.voucherClassActive = categoryWrapper.classId;
                            }
                            //push this category to a list of VOBE categories
                            if(cValue.friendlyName == 'featured') {
                                if(!hideCategoryFeatured) {
                                    vm.voucherClassCounter.push(categoryWrapper);
                                }
                            } else {
                                vm.voucherClassCounter.push(categoryWrapper);
                            }

                            //assign this category to VISIBLE vouchers
                            var eligibleVouchers = getEligibleVouchers(cValue);
                            if (eligibleVouchers && eligibleVouchers.length > 0) {
                                //set this category to ALL VISIBLE eligible vouchers
                                angular.forEach(eligibleVouchers, function (v, k) {
                                    v.categories = v.categories || {};
                                    v.categories[cValue.friendlyName] = categoryWrapper;
                                    //allow sorting by a category sequence
                                    v['sequence_'+cValue.friendlyName] = cValue.voucherIds.indexOf(v.id);
                                });
                            }
                        }
                    }
                });


                //UPDATE POOLS WITH SEQUENCE WITHIN CATEGORY
                updateCategorySequenceOnPools();

                //CALCULATE UNCATEGORIZED VOUCHERS
                var totalVouchers = vouchersResponse.nrOfElements ? vouchersResponse.nrOfElements : vouchersResponse.voucherPoolsCount;
                var categorizedVouchers = 0;
                angular.forEach(vm.voucherClassCounter, function (vcc, k) {
                    if(vcc.classId != 'all' && vcc.counter){
                        categorizedVouchers += vcc.counter;
                    }
                });
                vm.uncategorizedVouchers = totalVouchers - categorizedVouchers;


                /***USEFULLNESS OF CONTROLS ***/
                //Jun 2021: requested to hide controls when they are not of much help

                //U-1. COLUMN CONTROLS & U-2. SORTING CONTROLS
                if (vouchersResponse.voucherPoolsCount < 4 && viewSettings.viewLayoutTileActive == 'three') {
                    vm.showColumnControls = false;
                    vm.showSortControls = false;
                }
                if (vouchersResponse.voucherPoolsCount < 6 && viewSettings.viewLayoutTileActive == 'four') {
                    vm.showColumnControls = true;
                    vm.showSortControls = false;
                }

                //U-3. CATEGORIES BAR
                vm.showCategoriesBoxUseful = (
                    (vm.voucherClassCounter.length - (vm.hideCategoryAll ? 0 : 1) >= vm.showCategoriesBoxTreshold) //we have at least 2 (or other limit) categories
                    ||
                    (vm.uncategorizedVouchers > 0 && vm.voucherClassCounter.length - (vm.hideCategoryAll ? 0 : 1) >= 1) //we have uncategorized vouchers and at least 1 category
                );


                /**
                 * TRUSTED DESCRIPTION
                 */
                //if we have vouchers
                if (vouchersResponse && vouchersResponse.voucherPoolsCount > 0) {
                    //loop over vouchers to set some data
                    angular.forEach(vouchersResponse.wrapper.voucherPools, function (pool, poolkey) {
                        angular.forEach(pool.vouchers, function (v, vkey) {

                            if (pool.type == 'cash' || pool.type == 'cash-wildcard') {
                                //todo remove that in future
                                if(!pool.cashVoucherDescriptionTrustedHtml) {
                                    pool.cashVoucherDescriptionTrustedHtml = $sce.trustAsHtml(v.voucherDescription);
                                }
                            }
                            v.voucherDescriptionTrustedHtml = $sce.trustAsHtml(v.voucherDescription);
                            v.voucherDescription2TrustedHtml = !v.voucherDescription2 || v.voucherDescription2==null ? $sce.trustAsHtml('<span></span>') : $sce.trustAsHtml(v.voucherDescription2);
                        });
                    });
                }

                /**
                 * POOL TOTAL RATE, PRICE INTERVALS
                 */
                //if we have vouchers
                if (vouchersResponse && vouchersResponse.wrapper && vouchersResponse.wrapper.voucherPools) {

                    var tempIntervals = [];

                    //loop over vouchers to create intervals
                    //TODO: change to loop of pool min/max and step
                    angular.forEach(vouchersResponse.wrapper.voucherPools, function (pool, poolkey) {
                        //1. SET TOTAL RATE FOR SORTING, can be deleted now
                        pool.totalRate = pool.minValue;
                        //2. DO INTERVALS
                        angular.forEach(pool.vouchers, function (v, vkey) {
                            //$log.info('voucher loop', vkey, v);
                            //add the totalPrice to priceInterval counter...
                            //add the totalPrice to priceInterval counter...showCateg
                            //interval 10=28, means there is 28 vouchers in price range 10-19
                            //interval 1540=7 means there are 7 vouchers in price range 1540-1549
                            var currentIntervalPrice = Math.floor(v.totalRate.priceMin / 10) * 10;
                            tempIntervals.push(currentIntervalPrice);
                        });
                    });

                    if (!isGroup || (isGroup && !vouchersResponse.priceIntervalsCounter)) {

                        tempIntervals.forEach(function (x) {
                            vm.priceIntervalsCounter[x] = (vm.priceIntervalsCounter[x] || 0) + 1;
                        });
                    }
                    else {
                        vm.priceIntervalsCounter = vouchersResponse.priceIntervalsCounter;
                    }
                    $log.info('vm.priceIntervalsCounter', vm.priceIntervalsCounter);
                }

                if (
                    vouchersResponse &&
                    vouchersResponse.wrapper &&
                    vouchersResponse.wrapper.voucherPools &&
                    vouchersResponse.wrapper.voucherPools.length
                ) {
                    vm.cardsCounter = vouchersResponse.wrapper.voucherPools.length;
                }

                /**
                 * FEATURED
                 */
                //if we have vouchers
                vm.featuredVoucherPools = ($filter('filter')(vm.allVouchers.wrapper.voucherPools,vm.filterVouchersByFeatured));
                $timeout(function() {
                    vm.activateSlickFeatured();
                }, 500);

            }
        }

        var sendDataLayerProductClick = function (voucherPool, voucherId) {
            var dataLayer = $window.dataLayer = $window.dataLayer || [];
            console.log('item', voucherPool);
            var voucherObject = voucherPool.vouchers[Object.keys(voucherPool.vouchers)[0]];
            if(voucherId){
                voucherObject = voucherPool.vouchers[voucherId];
            }
            dataLayer.push({
                event: 'eec:productClick',
                ecommerce: {
                    click: {
                        products: [{
                            name: voucherObject.voucherName,                      // Name or ID is required.
                            id: voucherObject.id.toString(),
                            price: voucherObject.totalRateAfterDiscount.priceMin,
                            category: voucherObject.voucherType,
                            position: (voucherObject.sequence  < 0 ? 1 : voucherObject.sequence + 1)
                        }]
                    }
                }
            });
        };

        var sendDataLayerImpressions = function(category) {
            console.log('impressions: ', category);
            var dataLayer = $window.dataLayer = $window.dataLayer || [];

            //impressions array
            var impressionsGa4 = [];
            var matchedCategory = null;

            if(vm.allVouchers.wrapper) {
                for (var vp in vm.allVouchers.wrapper.voucherPools) {
                    if (vm.allVouchers.wrapper.voucherPools.hasOwnProperty(vp)) {
                        var voucherPool = vm.allVouchers.wrapper.voucherPools[vp];
                        var voucherObject = voucherPool.vouchers[Object.keys(voucherPool.vouchers)[0]];

                        // Check if any category in voucherObject matches the passed category
                         var matchesCategory = Object.values(voucherObject.categories || {}).some(function(voucherCategory) {

                            if (category === 'all') {
                                matchedCategory = {
                                    id: 1,
                                    name: 'All'
                                }
                                return true;
                            }
                            if (voucherCategory.category && voucherCategory.category.friendlyName === category) {
                                matchedCategory = voucherCategory.category;
                                return true;
                            }
                            return false;
                        });

                        if (matchesCategory) {

                            impressionsGa4.push({
                                item_id: voucherObject.id.toString(),
                                item_name: voucherObject.voucherName,
                                index: (voucherPool.sequence < 0 ? 1 : voucherPool.sequence + 1),
                                price: voucherObject.totalRateAfterDiscount.priceMin,
                                quantity: 1,
                                item_category: 'Gift Voucher',
                                affiliation: currentProperty.hotelName,
                            });
                        }

                    }
                }
            }

            if (impressionsGa4.length > 0) {
                //ga4
                dataLayer.push({
                    event: "view_item_list",
                    ecommerce: {
                        item_list_id: matchedCategory.id,
                        item_list_name: matchedCategory.name,
                        affiliation: "In1Solutions Gift Voucher App",
                        items: impressionsGa4
                    }
                });
                console.log('last dataLayer', dataLayer[dataLayer.length - 1]);
            }

        };


        function onCurrencyUpdated(currency){
            //console.log('onCurrencyUpdated',currency);
            if(!$localStorage.currencySettings){
                $localStorage.currencySettings = {};
            }
            var settings =  $localStorage.currencySettings;
            settings['displayCurrency_'+currentProperty.hotelId] = currency ? currency.code : undefined;
        }


    }
})();
